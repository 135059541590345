<template>
  <div class="autonym-step2-content">
    <div class="pl-24">
      <label class="require ml-10 text-18 text-main font-500">身份证照片 </label>
    </div>

    <div class="face-uploader-wrap bg-white flex justify-center">
      <tos-uploader class="face-uploader" isTakePhoto isFront v-model="faceFileList" :max-count="1">
        点击拍照
      </tos-uploader>
    </div>

    <div class="mt-24" style="height:0.72rem;">
      <div class="btn-box">
        <van-button size="large" :disabled="!next" @click="handleToNext" type="primary">下一步</van-button>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref, watch, onMounted } from '@vue/composition-api';
import TosUploader from '@/components/uploader/uploader.vue';
import { useUserVerifiedStore } from '@/store/verified';
import { Button, Toast } from 'vant';

export default defineComponent({
  components: {
    TosUploader,
    Toast,
    [Button.name]: Button
  },
  setup() {
    const userVerifiedStore = useUserVerifiedStore();
    const faceFileList = ref([]);
    const next = ref(false);

    watch(
      () => faceFileList.value,
      val => {
        if (val.length > 0) {
          next.value = true;
        }
      },
      { deep: true }
    );

    onMounted(() => {
      if (userVerifiedStore?.verifiedInfo?.selfImageUrl) {
        faceFileList.value = [
          {
            fileName: userVerifiedStore?.verifiedInfo?.selfImageUrl || '',
            status: 'uploading',
            message: '加载中'
          }
        ];
      }
    });

    function handleToNext() {
      // 缓存第二部的数据
      if (faceFileList?.value[0]?.fileName) {
        userVerifiedStore.updateInfo({
          selfImageUrl: faceFileList?.value[0]?.fileName
        });
        userVerifiedStore.saveInfo(); // 同步缓存
        userVerifiedStore.submitVerifiedInfo(); // 数据提交
      } else {
        Toast.fail('图片信息不能为空!');
      }
    }

    return {
      next,
      faceFileList,
      handleToNext
    };
  }
});
</script>

<style lang="less">
.autonym-step2-content {
  .face-uploader-wrap {
    .face-uploader {
      width: 3.5rem;
      height: 3.78rem;

      .tos-uploader-bg {
        border: none;
        border-radius: 50%;
        background-image: url('https://wlhyos-resource.oss-cn-hangzhou.aliyuncs.com/wlhyos/public/media/siji_xiaochegnxu/chatu_renxiangpaizhao%403x.jpg');
        background-size: 100% 100%;
        .uploader-button-wrap {
          display: none;
        }
      }

      .icon-delete {
        right: 0;
        top: 0;
      }
      .uploader-button {
        height: 100%;
      }
    }
  }
}
</style>
